<template>
  <v-dialog v-model="previewDialog" max-width="800px">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis ps-2"> {{ titulo }}</div>
        <v-btn icon @click="previewDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>  
      </v-card-title
      >
      <v-card-text>
        <div v-if="isImage" class="image-container mt-4">
          <img
            id="image"
            :src="fileSrc"
            alt="Pré-visualização da imagem"
            class="preview-image"
          />
        </div>
        <div v-else>
          <div class="holds-the-iframe" ref="container" data-cy="pdf-container">
            <iframe
              src=""
              id="iframe"
              ref="iframe_preview"
              name="iframe_preview"
              style="width: 100%; height: 600px; border: none; margin: 0px"
              type="application/pdf"
            ></iframe>
          </div>
        </div>
        <div
          v-show="showloading"
          style="height: 0px"
          class="wait-loading"
        ></div>
        <div class="d-flex justify-center mt-4" v-if="Array.isArray(documentos)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn 
                style="margin-right: 10px;"
                v-on="on"
                color="blue-grey" 
                @click="downloadArquivo"
                fab small dark><v-icon>mdi-download</v-icon></v-btn>
            </template>
            <span>Baixar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn 
                style="margin-right: 10px;"
                v-on="on"
                color="blue-grey" 
                @click="rotateImage"
                fab small dark><v-icon>mdi mdi-format-rotate-90</v-icon></v-btn>
            </template>
            <span>Girar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn 
                style="margin-right: 10px;"
                v-on="on"
                color="blue-grey" 
                @click="visualizarListaDocumento"
                fab small dark><v-icon>mdi mdi-arrow-right</v-icon></v-btn>
            </template>
            <span>Próximo</span>
          </v-tooltip>
        </div>
      </v-card-text>
      
      <v-col cols="12" class="d-flex justify-end mt-4">
        <v-btn @click="previewDialog = false" color="white" class="mx-2">Fechar</v-btn>
      </v-col>
    </v-card>
  </v-dialog>
</template>
  
<script>
import JSZip from "jszip"

export default {
  data() {
    return {
      previewDialog: false,
      fileSrc: null,
      isImage: false,
      showloading: true,
      countImagens: 0,
      documentos: null,
      tipoDocumento: null,
      codigo: null,
      titulo: '',
      rotation: 0
    }
  },
  methods: {
    async showPreview(files, fileType, codigo) {
      this.codigo = codigo
      this.previewDialog = true
      this.isImage = false
      this.showloading = true
      this.documentos = null
      this.tipoDocumento = null
      this.countImagens = 0

      await this.sleep(50)
      try {
        
        if (Array.isArray(files)) {
          this.documentos = files
          this.tipoDocumento = fileType

          this.titulo = 'Biometria'
          this.visualizarListaDocumento()

          return
        }

        this.titulo = 'Pré-visualização de documento'
        this.visualizarDocumentoUnico(files, fileType)
      } catch (error) {
        console.error('Erro ao pré-visualizar o documento:', error)
      }
    },
    sleep(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms)
      })
    },
    visualizarDocumentoUnico(file, fileType) {
      this.fileSrc = null

      const binaryString = window.atob(file)
      const len = binaryString.length
      const bytes = new Uint8Array(len)

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }

      if (fileType.endsWith('pdf')) {
        const pdfBlob = new Blob([bytes.buffer], { type: fileType })
        const url = URL.createObjectURL(pdfBlob)
        this.$refs.iframe_preview.src = url
      }

      if (fileType.startsWith('image/')) {
        this.isImage = true
        const imagemBlob = new Blob([bytes.buffer], { type: fileType })
        this.fileSrc = URL.createObjectURL(imagemBlob)
        
        if (this.$refs.iframe_preview !== undefined)
          this.$refs.iframe_preview.src = ''
      }

      this.showloading = false
      window.dispatchEvent(new Event('resize'))
    },
    visualizarListaDocumento() {
      this.rotation = -90

      if (this.countImagens != 0) {
        this.visualizarDocumentoUnico(this.documentos[this.countImagens], this.tipoDocumento)
        
        if (this.countImagens == (this.documentos.length - 1)) {
          this.countImagens = 0
          return
        }
      
        this.countImagens++
      } else {
        this.visualizarDocumentoUnico(this.documentos[this.countImagens], this.tipoDocumento)
        this.countImagens++
      }
      
      this.rotateImage()
    },
    rotateImage() {
      const th = this
      th.rotation = (th.rotation + 90) % 360

      const image = document.getElementById('image')

      if (image != null && image.style != null)
        image.style.transform = `rotate(${th.rotation}deg)`
    },
    downloadArquivo() {
      let imagens = []

      for (let count = 0; count < this.documentos.length; count++) {
        const byteCharacters = atob(this.documentos[count]);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        imagens.push(new Uint8Array(byteNumbers));
      }

      const zip = new JSZip();

      imagens.forEach((image, index) => {
          zip.file(`Imagem_${index + 1}.jpg`, image, { binary: true });
      });

      zip.generateAsync({ type: "blob" })
        .then((content) => {

            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = "BiometriaFacial.zip";
            link.style.display = "none";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
      )
    }
  }
}
</script>
  
<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin: auto;
}

</style>
  