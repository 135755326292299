import util from '@bmp-vue/core/api/util'

const controller = 'correntista-pj-socio'
const versao = 'v1'

export default Object.assign(
  {},
  util.GetCrud(controller, ['getall', 'get', 'post', 'put', 'persist']),
  {
    ObterTodosPorCorrentista: function (dto) {
      return util.Axios.GetAll(`${versao}/${controller}`, dto)
    },
    ObterPorCodigo: function (codigo) {
      return util.Axios.Get(`${versao}/${controller}`, codigo)
    },
    InserirArquivo: function (request) {
      return util.Axios.Post(`${versao}/${controller}` + '/documentos', request)
    },
    ObterSocioSolicitacao: function (codigoPessoaDado) {
      return util.Axios.Get(
        `${versao}/${controller}` + '/socio-solicitacao',
        codigoPessoaDado
      )
    },
    InserirTermoSocios: function (request) {
      return util.Axios.Post(
        `${versao}/${controller}` + '/termo-aceite-socios',
        request
      )
    }
  }
)
