<template>
  <div>
    <b><span>Resumo:</span></b>
    <br><br>
    <div>
      <div>
        <span v-show="ExisteAnalise(resumo.situacaoObitoFiscal)">         <strong>Óbito e fiscal:</strong> {{ ObterDescricao(resumo.situacaoObitoFiscal) }} <br></span>
        <span v-show="ExisteAnalise(resumo.situacaoCadastroRestritivo)">  <strong>Cadastro restritivo:</strong> {{ ObterDescricao(resumo.situacaoCadastroRestritivo) }} <br></span>
        <span v-show="ExisteAnalise(resumo.situacaoMidiaDesabonadora)">   <strong>Mídia desabonadora:</strong> {{ ObterDescricao(resumo.situacaoMidiaDesabonadora) }} <br></span>
        <span v-show="ExisteAnalise(resumo.situacaoEmailAge)">            <strong>E-mail age:</strong> {{ ObterDescricao(resumo.situacaoEmailAge) }} <br></span>
        <span v-show="ExisteAnalise(resumo.situacaoCriminalResumo)">      <strong>Análise restritiva:</strong> {{ ObterDescricao(resumo.situacaoCriminalResumo) }} <br></span>     
        <span v-show="ExisteAnalise(resumo.situacaoCriminalDetalhe)">     <strong>Criminal detalhe:</strong> {{ ObterDescricao(resumo.situacaoCriminalDetalhe) }} <br></span>
        <span v-show="ExisteAnalise(resumo.situacaoDataBusca)">           <strong>Análise cadastral:</strong> {{ ObterDescricao(resumo.situacaoDataBusca) }} <br></span>
        <span v-show="ExisteAnalise(resumo.situacaoQuodRufra)">           <strong>Indício de fraude:</strong> {{ ObterDescricao(resumo.situacaoQuodRufra) }} <br></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onboarding: Object,
  },
  data() {
    return {
      resumo: {}
    }
  },
    methods: {
      alimentarObjeto() {
        let th = this
        Object.assign(th.resumo, th.onboarding)   
        th.refresh();
      },

      ObterDescricao(valor){
        let th = this
        if(valor) 
          return th.$api.Arrays.DescricaoValidacaoOnboardingDetalhe.find((arr) => arr.value == valor).text
        
        return "Validação não encontrada."  
      },

      ExisteAnalise(valor){
        if(valor) 
          return true 
      },

      refresh(){
        this.$forceUpdate();  
      }
  },
}
</script>
<style scoped>
</style>
