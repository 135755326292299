<template>
  <div>
    <ConfirmacaoAnalise @close="responseReconsulta" ref="confirmaConsulta"/>
    <div class="custom-div">
      <span>Situação bureau: {{ consultaBureau.msgSituacaoBureau }} </span>
      <span>Número execuções: {{ consultaBureau.numExecucao }}</span>
      <span>Data última consulta: {{ consultaBureau.dtSituacao }}</span>
    </div>
    <br />
    <div class="custom-div-btn">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on" 
              v-show="mostrarBotaoDownload" 
              :disabled="bloquearDownload"
              color="blue-grey" 
              @click="buscarArquivo"
              fab small dark><v-icon>mdi-download</v-icon></v-btn>
          </template>
          <span>Download arquivo</span>
        </v-tooltip>
      <span>&nbsp;&nbsp;</span>
        <v-tooltip bottom v-if="!isHistorico">
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on" 
              :disabled="bloquearConsulta" 
              color="blue-grey" 
              @click="consultar"
              fab small dark><v-icon>mdi-database-search</v-icon></v-btn>
          </template>
          <span>Consultar novamente</span>
        </v-tooltip>
    </div>
    <br><br>
    <strong><U><span>{{ titulo.replace('PJ', '') }}</span></U></strong> 
    <br><br>
    <div>
      <div v-for="(valor, chave) in bureauDetalhes" :key="chave"> <strong>{{ chave }}</strong>: {{ valor }} </div>
    </div>

    <div v-show="mostrarInfos('criminal detalhe')">
      <br><strong><U><span>Informações Processos</span></U></strong><br><br>
      <div v-if="bureauDetalhesCriminalDetalheParticipantesLista.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesCriminalDetalheLista" :key="chave">
          <span> <strong>Número:</strong>  {{ valor.Number }} </span><br>
          <span> <strong>Tipo:</strong>  {{ valor.Type }} </span><br>
          <span> <strong>Assunto Principal:</strong>  {{ valor.MainSubject }} </span><br>
          <span> <strong>Tipo de Tribunal:</strong>  {{ valor.CourtType }} </span><br>
          <span> <strong>Situação:</strong>  {{ valor.Status }} </span><br>
          <div v-for="(valorLista, chaveLista) in valor.Participantes" :key="chaveLista"> 
            <span> <strong>Nome {{ definirTipoParticipanteCriminalDetalhe(valorLista.Polarity) }}:</strong>  {{ valorLista.Name }} </span><br>
          </div><br>
        </div><br><br> 
      </div> 
      <div v-else>
          <span>Não existem processos criminais para este documento federal.</span><br><br>
      </div>    
    </div>

    <div v-show="mostrarInfos('indício de fraude')">
      <br><strong><U><span>Fraudes</span></U></strong><br><br>
      <div v-if="bureauDetalhesIndiceFraudeLista.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesIndiceFraudeLista" :key="chave">
          <span> <strong>Data evento:</strong>  {{ valor.DataEvento }} </span><br>
          <span> <strong>Classificação:</strong>  {{ valor.Nome }} </span><br>
          <span> <strong>Motivo:</strong></span>
          <ul>
            <li>
              <span> <strong>Tipo:</strong>  {{ valor.Motivo.Nome }} </span><br>
            </li>
            <li>
              <span> <strong>Descrição:</strong>  {{ valor.Motivo.Descricao }} </span><br>
            </li>
          </ul>
        <br>
        </div><br><br> 
      </div>
      <div v-else>
        <span>Não existe motivo para este documento federal.</span><br><br>
      </div> 
    </div>

    <div v-show="mostrarInfos('análise cadastral') || mostrarInfos('análise cadastral pj')">
      <br><strong><U><span>Contatos/Endereços</span></U></strong><br><br>
      <span> <strong>Endereço:</strong> {{ bureauDetalhesAnaliseCadastralContatosEnderecos.Endereco }} </span><br>
      <span> <strong>CEP:</strong> {{ bureauDetalhesAnaliseCadastralContatosEnderecos.CEP }} </span><br>
      <span> <strong>Cidade:</strong> {{ bureauDetalhesAnaliseCadastralContatosEnderecos.Cidade }} </span><br>
      <span> <strong>E-mail:</strong> {{ bureauDetalhesAnaliseCadastralContatosEnderecos.Email }} </span><br>
      <span> <strong>Telefone:</strong> {{ bureauDetalhesAnaliseCadastralContatosEnderecos.Telefone }} </span><br>
      <span> <strong>Telefone:</strong> {{ bureauDetalhesAnaliseCadastralContatosEnderecos.TelefoneRecente }} </span><br>

      <br><strong><U><span>Financeiro</span></U></strong><br><br>
      <span> <strong>Renda:</strong> R$ {{ $api.UI.Format.Decimal(bureauDetalhesAnaliseCadastralFinanceiro.Renda) }} </span><br>
      <span v-show="mostrarInfos('análise cadastral pj')"> <strong>Renda presumida:</strong> R$ {{ $api.UI.Format.Decimal(bureauDetalhesAnaliseCadastralFinanceiro.RendaPresumida) }} <br></span>
      <span> <strong>Score de crédito:</strong> {{ bureauDetalhesAnaliseCadastralFinanceiro.ScoreCredito }} </span><br>

      <span><strong>Veículos</strong>{{ bureauDetalhesAnaliseCadastralVeiculos == null || bureauDetalhesAnaliseCadastralVeiculos?.length <= 0 ? ': Não consta' : '' }} </span><br>
      <div v-if="bureauDetalhesAnaliseCadastralVeiculos?.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralVeiculos" :key="chave">
          <br><span> <strong>Marca:</strong>  {{ valor?.Brand }} </span><br>
          <span> <strong>Modelo:</strong>  {{ valor?.Model }} </span><br>
          <span> <strong>Ano de fabricação/Modelo:</strong>  {{ valor?.YearManufacturing }}/{{ valor?.YearModel }} </span><br>
          <span> <strong>Placa:</strong>  {{ valor?.LicensePlate }} </span><br>
          <span> <strong>Data da compra:</strong>  {{ $api.UI.Format.Date(valor?.LicenseDate) }} </span><br><br>
        </div>
      </div>
      
      <span v-if="mostrarInfos('análise cadastral pj')"><U><strong><br>Matriz/Filiais</strong></U>{{ bureauDetalhesAnaliseCadastralFiliais == null || bureauDetalhesAnaliseCadastralFiliais?.length <= 0 ? ': Não consta' : '' }} <br><br></span>
      <div v-if="bureauDetalhesAnaliseCadastralFiliais?.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralFiliais" :key="chave">
          <span> <strong>Documento federal:</strong>  {{ valor?.DocumentFormatted }} </span><br>
          <span> <strong>Empresa:</strong>  {{ valor?.Name }} </span><br>
          <span> <strong>Data abertura:</strong>  {{ $api.UI.Format.Date(valor?.CreateDate) }} </span><br><br>          
        </div>
      </div>

      <span v-if="mostrarInfos('análise cadastral pj')"><U><strong><br>CNAES</strong></U>{{ bureauDetalhesAnaliseCadastralCNAES == null || bureauDetalhesAnaliseCadastralCNAES?.length <= 0 ? ': Não consta' : '' }} <br><br></span>
      <div v-if="bureauDetalhesAnaliseCadastralCNAES?.length">
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralCNAES" :key="chave">
            <span> <strong>CNAE:</strong>  {{ formatarNumeroCNAE(valor?.Code?.toString()) }} </span><br>
            <span> <strong>Descrição:</strong>  {{ valor?.Description }} </span><br><br>
        </div>
      </div>

      <br><span><strong><U>{{ mostrarInfos('análise cadastral') ? 'Sociedades' : 'Sócios' }}</U></strong> {{ bureauDetalhesAnaliseCadastralSociedades == null || bureauDetalhesAnaliseCadastralSociedades?.length <= 0 ? ': Não consta' : '' }} <br><br></span>
      <div v-if="mostrarInfos('análise cadastral') && bureauDetalhesAnaliseCadastralSociedades?.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralSociedades" :key="chave">
          <span> <strong>Empresa:</strong>  {{ valor.Name }} </span><br>
          <span> <strong>Documento federal:</strong>  {{ valor.DocumentFormatted }} </span><br>
          <span> <strong>Sócios:</strong>  {{ valor.QuantidadeSocios }} </span><br>
          <span v-if="valor.StatusDescription !== 'History'"> <strong>Data de entrada:</strong>  {{ $api.UI.Format.Date(valor.DataEntrada) }} <br></span>
          <span v-else> <strong>Situação:</strong>  Baixada <br></span>
          <span> <strong>Participação:</strong>  {{ formatarTipoParticipacao(valor.RelationTypeDescription) }} - <strong>Porcentagem:</strong> {{ valor.Quota }}% </span><br><br>
        </div>
      </div> 
      <div v-else-if="bureauDetalhesAnaliseCadastralSociedades?.length > 0 && bureauDetalhesAnaliseCadastralSociedades[0]?.Partners?.length > 0"> 
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralSociedades[0]?.Partners" :key="chave">
          <span> <strong>Documento federal:</strong>  {{ valor?.DocumentFormatted }} </span><br>
          <span> <strong>Nome:</strong>  {{ valor?.Name }} </span><br>
          <span> <strong>Data de entrada:</strong>  {{ $api.UI.Format.Date(valor?.EntryDate) }} </span><br>
          <span> <strong>Porcentagem:</strong> {{ valor?.Quote }}% </span><br><br>
        </div>
      </div>
      
      <strong><U><span>Outros</span></U></strong><br><br>
      <span><strong><U>CCF (Cheque sem fundo)</U></strong>{{ bureauDetalhesAnaliseCadastralCCF == null || bureauDetalhesAnaliseCadastralCCF?.length <= 0 ? ': Não consta' : '' }} <br></span>
      <div v-if="bureauDetalhesAnaliseCadastralCCF?.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralCCF" :key="chave">
          <span> <strong>Código banco/Banco:</strong>  {{ valor?.Bank }} - {{ valor?.Name }} </span><br>
          <span> <strong>Agência:</strong>  {{ valor?.Agency }} </span><br>
          <span> <strong>Motivo:</strong>  {{ valor?.Reason }} </span><br>
          <span> <strong>Ativos:</strong>  {{ valor?.Active == null ? 0 : valor?.Active?.Quantity }} </span><br>
          <span> <strong>Pagos:</strong>  {{ valor?.Payed == null ? 0 : valor?.Payed?.Quantity }} </span><br>
          <span> <strong>Expirados:</strong>  {{ valor?.Expired == null ? 0 : valor?.Expired?.Quantity }} </span><br>
          <span> <strong>Última ocorrência:</strong>  {{ $api.UI.Format.Date(valor?.Active?.LastDate)  }} </span><br><br>
        </div>
      </div>

      <span><strong><U>Mandados</U></strong>{{ bureauDetalhesAnaliseCadastralMandados == null || bureauDetalhesAnaliseCadastralMandados?.length <= 0 ? ': Não consta' : '' }} <br></span>
      <div v-if="bureauDetalhesAnaliseCadastralMandados?.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralMandados" :key="chave">
          <span> <strong>Situação:</strong>  {{ valor?.WarrantStatus }} </span><br>
          <span> <strong>Número:</strong>  {{ valor?.Process }} </span><br>
          <span> <strong>Data:</strong>  {{ valor?.CrimeDate == null ? 'Não consta' : $api.UI.Format.Date(valor?.CrimeDate) }} </span><br>
          <span> <strong>Data cumprimento:</strong>  {{ valor?.ConclusionDate == null ? 'Não consta' :  $api.UI.Format.Date(valor?.ConclusionDate) }} </span><br>
          <span> <strong>Assuntos do delito:</strong>  {{ valor?.Subject == null ? 'Não consta' : valor?.Subject }} </span><br><br>
        </div>
      </div>

      <span><strong><U>Restrições</U></strong>{{ bureauDetalhesAnaliseCadastralRestricoes == null ||  bureauDetalhesAnaliseCadastralRestricoes?.length <= 0 ? ': Não consta' : '' }} <br></span>
      <div v-if="bureauDetalhesAnaliseCadastralRestricoes?.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesAnaliseCadastralRestricoes" :key="chave">
          <span> <strong>Restrições:</strong>  {{ valor?.Description }} </span><br>
          <span> <strong>Processos:</strong>  {{ valor?.ProcessNumber == null ? 'Não consta' : valor?.ProcessNumber }} </span><br>
          <span> <strong>Publicação:</strong>  {{ valor?.PublishDate == null ? 'Não consta' : $api.UI.Format.Date(valor?.CrimeDate) }} </span><br><br>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    refresh: Function,
    bureau: Object,
    onboarding: Object,
    contexto: String,
    isHistorico: Boolean
  },
  components: {
    ConfirmacaoAnalise: () => import('../ConsultaIndividualConfirmacao'),
  },
  data() {
    return {
      titulo: '',    
      consultaBureau: {},
      bureauDetalhesCriminalDetalhe: {},
      bureauDetalhesCriminalDetalheParticipante: {},
      bureauDetalhesCriminalDetalheParticipantesLista:[],
      bureauDetalhesCriminalDetalheLista: [],
      bureauDetalhes: {},
      bloquearConsulta: false,
      bloquearDownload: false,
      mostrarBotaoDownload: false,
      bureauDetalhesIndiceFraudeLista: [],
      bureauDetalhesAnaliseCadastralFinanceiro: {},
      bureauDetalhesAnaliseCadastralVeiculos: {},
      bureauDetalhesAnaliseCadastralSociedades: [],
      bureauDetalhesAnaliseCadastralCCF: [],
      bureauDetalhesAnaliseCadastralMandados: [],
      bureauDetalhesAnaliseCadastralRestricoes: [],
      bureauDetalhesAnaliseCadastralContatosEnderecos: {},
      bureauDetalhesAnaliseCadastralCNAES: [],
      bureauDetalhesAnaliseCadastralFiliais: []
    }
  },
  methods: {
    consultar() {
      let th = this

      let dto = {
        codigoParceiro: th.onboarding.codigoParceiro,
        documentoFederal: th.onboarding.documentoFederal,
        contexto: th.onboarding.contexto,
        tipoBureau: th.consultaBureau.bureau,
        codigoOnboarding: th.onboarding.codigo
      } 
      th.$refs.confirmaConsulta.openModal(th.titulo, dto)
    },
    buscarArquivo() {
      let th = this

      th.$api['analise-onboarding'].BuscarArquivo(th.onboarding.codigo, th.consultaBureau.bureau).then(function (response) { 
            if(th.$api.UI.PostAction(response))
              th.downloadArquivo(response);                
          }
        );
    },
    downloadArquivo(arquivo) {
      let th = this
        
      const blob = new Blob([JSON.stringify(arquivo, null, 3)], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const formato = 'txt'

      const nomeArquivo = `Analise_DocumentoFederal_${th.onboarding.documentoFederal}.${formato}`
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
    },
    preencherInformacoes() {
      let th = this
      Object.assign(th.consultaBureau, th.bureau)
      
      th.consultaBureau.dtSituacao = th.$api.UI.Format.DateTime(th.consultaBureau.dtSituacao)

      if (th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao.find((te) => te.text.toLowerCase() == 'executado').value
          || th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao.find((te) => te.text.toLowerCase() == 'validado').value) {
        th.bloquearConsulta = false
        th.bureauDetalhes = JSON.parse(th.consultaBureau.jsonRetornoBureauTratado ?? "{}")
        th.titulo = `${th.$api.Arrays.TipoBureau.find((arr) => arr.value == this.consultaBureau.bureau).text}`

        th.formatarObitoFiscal()
        th.formatarMidia()
        th.formatarCriminalDetalhe()
        th.formatarAnaliseCadastral()
        th.formatarIndicioFraude()
      }

      if (th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao.find((te) => te.text.toLowerCase() == 'pendente').value
        || th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao.find((te) => te.text.toLowerCase() == 'executando').value) {
        th.bloquearConsulta = true
        th.bloquearDownload = true
        th.mostrarBotaoDownload = false
        th.bureauDetalhes = {}
        th.titulo = 'Consulta em andamento, por favor aguarde.'
      }

      if (th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao.find((te) => te.text.toLowerCase() == 'ignorado').value) {
        th.bloquearDownload = true
        th.bloquearConsulta = false
        th.bureauDetalhes = {}
        th.titulo = 'Ocorreu um erro na consulta, por favor tente novamente.'
      }
      const bureauCriminalDetalhe = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'criminal detalhe').value
      const bureauCriminalResumo = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'análise restritiva').value
      const bureauDataBusca = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'análise cadastral').value
      const bureauDataBuscaPJ = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'análise cadastral pj').value
      
      if (th.consultaBureau.bureau == bureauCriminalResumo
        || th.consultaBureau.bureau == bureauCriminalDetalhe
        || th.consultaBureau.bureau == bureauDataBusca
        || th.consultaBureau.bureau == bureauDataBuscaPJ)
        th.mostrarBotaoDownload = true
    },
    response() {
      let th = this
      th.show = false
    },
    responseReconsulta() {
      let th = this
      th.$emit('reconsulta')
      th.show = false
    },
    mostrarInfos(nomeBureau){
      let th = this
      return th.consultaBureau.bureau == th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == nomeBureau).value &&
              th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao.find((te) => te.text.toLowerCase() == 'validado').value
    },
    formatarCriminalDetalhe() {
      let th = this
      const bureauCriminalDetalhe = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'criminal detalhe').value

      if (th.consultaBureau.bureau == bureauCriminalDetalhe){
        th.listarAcoesCriminais()
        delete th.bureauDetalhes['Ações criminais']
        delete th.bureauDetalhes['Parties']
      }
    },
    listarAcoesCriminais(){
      let th = this
      for (const item of th.bureauDetalhes['Ações criminais']) 
        {
          Object.assign(th.bureauDetalhesCriminalDetalhe, item) 
          th.listarParticipantes()            
          th.bureauDetalhesCriminalDetalheLista.push(th.bureauDetalhesCriminalDetalhe)
          th.bureauDetalhesCriminalDetalhe = {}
        }
    },
    listarParticipantes(){
      let th = this
      for (const itemParticipante of th.bureauDetalhesCriminalDetalhe['Parties']) 
          {
            Object.assign(th.bureauDetalhesCriminalDetalheParticipante, itemParticipante)                  
            th.bureauDetalhesCriminalDetalheParticipantesLista.push(th.bureauDetalhesCriminalDetalheParticipante)
            th.bureauDetalhesCriminalDetalheParticipante = {}
          }
          th.bureauDetalhesCriminalDetalhe.Participantes = th.bureauDetalhesCriminalDetalheParticipantesLista
    },
    definirTipoParticipanteCriminalDetalhe(valor){   
      return valor.toLowerCase() == 'active' ? 'Autor' : 'Réu'
    },
    formatarObitoFiscal() {
      let th = this
      const bureauObitoFiscal = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'óbito e fiscal').value

      if (th.consultaBureau.bureau == bureauObitoFiscal)
        th.bureauDetalhes['Indicação de óbito'] = th.bureauDetalhes['Indicação de óbito'] == false ? 'NEGATIVO' : 'POSITIVO'
    },
    formatarMidia() {
      let th = this
      const informacoesNaoEncontradas = 'Informação não encontrada.'
      const camposMidia = ['Total de páginas', 'Total de notícias no último ano', 'Total de notícias no último semestre', 'Total de notícias no último trimestre', 'Total de notícias nos últimos 30 dias', 'Total de notícias nos últimos 7 dias', 'Total de notícias']
      const bureauMidia = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'mídia desabonadora').value

      if (th.consultaBureau.bureau != bureauMidia) return

      for (let contador = 0; contador < camposMidia.length; contador++)
        th.bureauDetalhes[camposMidia[contador]] = th.bureauDetalhes[camposMidia[contador]] < 0 ? informacoesNaoEncontradas : th.bureauDetalhes[camposMidia[contador]]

      th.bureauDetalhes['Nível de exposição midiática'] = !th.bureauDetalhes['Nível de exposição midiática'] ? 0 : th.bureauDetalhes['Nível de exposição midiática']
      th.bureauDetalhes['Nível de celebridade'] = !th.bureauDetalhes['Nível de celebridade'] ? 0 : th.bureauDetalhes['Nível de celebridade']
      th.bureauDetalhes['Nível de impopularidade'] = !th.bureauDetalhes['Nível de impopularidade'] ? 0 : th.bureauDetalhes['Nível de impopularidade']  
    },
    formatarIndicioFraude() {
      let th = this
      const bureauIndicioFraude = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'indício de fraude').value
      
      if (th.consultaBureau.bureau != bureauIndicioFraude) return
      
      th.bureauDetalhesIndiceFraudeLista = []

      if (th.bureauDetalhes["Classificação de risco"] == "neutral")
        th.bureauDetalhes["Classificação de risco"] = "Neutro"
      else if (th.bureauDetalhes["Classificação de risco"] == "medium")
        th.bureauDetalhes["Classificação de risco"] = "Médio"
      else
        th.bureauDetalhes["Classificação de risco"] = "Grande"

      th.bureauDetalhesIndiceFraudeLista.push(...th.bureauDetalhes.Fraudes)
      th.bureauDetalhes.Motivos = th.bureauDetalhes.Motivos.join(', ')
      
      delete th.bureauDetalhes.Fraudes
    },
    formatarAnaliseCadastral() {
      let th = this
      const bureauAnaliseCadastral = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'análise cadastral').value
      const bureauAnaliseCadastralPJ = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'análise cadastral pj').value
      
      if (th.consultaBureau.bureau != bureauAnaliseCadastral && th.consultaBureau.bureau != bureauAnaliseCadastralPJ) return
      
      th.obterDadosPessoaisAnaliseCadastral()
      th.obterEnderecoContatos()
      
      th.bureauDetalhesAnaliseCadastralVeiculos = th.bureauDetalhes.Veiculos
      th.bureauDetalhesAnaliseCadastralSociedades = th.bureauDetalhes.Sociedades
      th.bureauDetalhesAnaliseCadastralCCF = th.bureauDetalhes.ChequeSemFundo
      th.bureauDetalhesAnaliseCadastralMandados = th.bureauDetalhes.Mandados
      th.bureauDetalhesAnaliseCadastralRestricoes = th.bureauDetalhes.Restricoes
      th.bureauDetalhesAnaliseCadastralCNAES = th.bureauDetalhes.CNAES
      th.bureauDetalhesAnaliseCadastralFiliais = th.bureauDetalhes.Filiais
      
      th.deletarCamposAnaliseCadastral()
    },
    obterDadosPessoaisAnaliseCadastral() {
      let th = this

      th.bureauDetalhesAnaliseCadastralFinanceiro = {
        Renda: th.mostrarInfos('análise cadastral') ? th.bureauDetalhes?.Renda?.Personal : th.bureauDetalhes?.Renda?.Shared,
        RendaPresumida: th.bureauDetalhes?.Renda?.Presumed,
        ScoreCredito: th.bureauDetalhes?.ScoreCredito?.D00
      }
    },
    formatarEndereco(endereco) {
      return endereco == null ? 'Não consta' : `R ${endereco?.Street}, ${endereco?.Number} - ${endereco?.ComplementFormatted}, ${endereco?.City} - ${endereco?.State} (CEP: ${endereco?.ZipCode})`
    },
    obterEnderecoContatos() {
      let th = this

      th.bureauDetalhesAnaliseCadastralContatosEnderecos = {
        Endereco: th.bureauDetalhes['Endereço'],
        CEP: th.bureauDetalhes.CEP,
        Cidade: th.bureauDetalhes.Cidade,
        Email: th.bureauDetalhes['E-mail'],
        Telefone: th.bureauDetalhes.Telefone != null ? th.bureauDetalhes.Telefone : th.bureauDetalhes.TelefoneRecente,
        TelefoneRecente: th.bureauDetalhes.TelefoneRecente
      }
    },
    deletarCamposAnaliseCadastral() {
      let th = this

      delete th.bureauDetalhes.Filiacao
      delete th.bureauDetalhes.ScoreCredito
      delete th.bureauDetalhes.Renda
      delete th.bureauDetalhes.Veiculos
      delete th.bureauDetalhes.Sociedades
      delete th.bureauDetalhes.ChequeSemFundo
      delete th.bureauDetalhes.Mandados
      delete th.bureauDetalhes.Restricoes
      delete th.bureauDetalhes['Endereço'],
      delete th.bureauDetalhes.CEP,
      delete th.bureauDetalhes.Cidade,
      delete th.bureauDetalhes['E-mail'],
      delete th.bureauDetalhes.Telefone
      delete th.bureauDetalhes.TelefoneRecente
      delete th.bureauDetalhes.CNAES
      delete th.bureauDetalhes.Filiais
    },
    formatarTipoParticipacao(tipoParticipacao) {
      if (tipoParticipacao != 'Direct') return tipoParticipacao

      return 'Direta'
    },
    formatarNumeroCNAE(cnae) {
      const valor = cnae.replace(/\D/g, '');
      return valor.replace(/(\d{2})(\d{2})(\d{1})(\d{2})/, "$1.$2-$3/$4");
    }
  },
  created() {
    const th = this;
    th.preencherInformacoes()

    th.$watch('bureau', () => {
      th.preencherInformacoes();
    }, { deep: true })
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.custom-div{
  background-color: rgb(243, 236, 236);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between
}

.custom-div-btn{
  display: flex;
  justify-content: flex-end;
  margin-bottom: -70px;
  margin-right: 10px;
}

.v-stepper__wrapper{
  padding-bottom: 30px;
}
</style>
