<template>
  <modal-form v-model="show" size="sm">
    <template v-slot:title>{{ titulo }}</template>
    <template v-slot:body>
      <v-row>
        <v-col cols="12">
          <div class="msg">
            <p>{{ mensagem }}</p>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="flex-grow-1"></div>
      <v-btn color="error" @click="closeModal">{{ textoBotaoFechar }}</v-btn>
      <v-btn color="primary" @click="confirmar" v-if="botaoConfirmar">Confirmar</v-btn>
    </template>
  </modal-form>
</template>

  <script>
export default {
  props: {
    contextoConfirmacao: String
  },
  components: {},
  data() {
    return {
      titulo: '',
      mensagem: '',
      show: false,
      model: {},
      botaoConfirmar: true,
      textoBotaoFechar: 'Cancelar'
    }
  },
  methods: {
    openModal(bureau, dto) {
      let th = this

      th.show = true
      th.botaoConfirmar = true
      th.textoBotaoFechar = 'Cancelar'
      th.model = { ...dto }
      th.titulo = 'Solicitar nova consulta'
      th.mensagem = `Deseja consultar novamente o bureau ${bureau.replace(':', '')}?`
    },
    confirmar() {
      let th = this

      th.$api['analise-onboarding'].RealizarAnaliseIndividual(th.model).then((response) => {
        if (!th.$api.UI.PostLoadData(response))
          return

        th.titulo = 'Nova consulta solicitada'
        th.mensagem = 'Você receberá um email informando que a consulta foi concluída.'
        th.botaoConfirmar = false
        th.textoBotaoFechar = 'Fechar'
      })
    },
    closeModal(){
      let th = this

      th.bureau = ''
      th.botaoConfirmar = false
      th.show = false
      th.model = {}
      th.titulo = ''
      th.mensagem = ''
      th.textoBotaoFechar = ''
      th.$emit('close', true)
    }
  }
}
</script>
  
  <style scoped>
.msg {
  color: black;
}
</style>
  