<template>
  <div>
    <v-card>
      <core-grid
        ref="subGrid"
        :searchDisabled="true"
        :refreshOption="false"
        :onRead="onRead"
        :schema="schema"
        :delete-option="false"
        :exportExcelOption="false"
        :edit-option="false"
        :add-option="false"
        item-key="codigo"
        server-side
      >
        <template #toolbar-append></template>
      </core-grid>
    </v-card>
  </div>
</template>
  
  <script>
export default {
  props: {
    schema: {
      type: Array,
      required: true
    },
    onRead: {
      type: Function,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.subGrid.refresh()
    },
    closeModal(data) {
      this.refresh()
    }
  }
}
</script>