import util from '@bmp-vue/core/api/util'

const controller = 'correntistas'
const versao = 'v1'

export default Object.assign({}, util.GetCrud(controller, null), {
  GetComRelacionamentos: function (id) {
    return util.Axios.Get(`${versao}/${controller}` + '/relacionamentos', id)
  },
  DownloadArquivo: function (localArquivo) {
    return util.Axios.Get(`${versao}/${controller}` + `/documentos?localArquivo=${localArquivo}`)
  },
  InserirArquivo: function (request) {
    return util.Axios.Post(`${versao}/${controller}` + '/documentos', request)
  },
  InserirTermo: function (dto) {
      return util.Axios.Post(`${versao}/${controller}` + '/termo-aceite', dto);
  },
  ObterCorrentistaSolicitacao: function (codigoPessoaDado) {
    return util.Axios.Get(`${versao}/${controller}` + '/correntista-solicitacao', codigoPessoaDado)
  }
})
