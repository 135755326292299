import util from '@bmp-vue/core/api/util'

const controller = 'onboarding-contas'
const versao = 'v1'

export default Object.assign({}, util.GetCrud(controller, null), {
    ListarOnboardingContas: function (dto) {
        return util.Axios.GetAll(`${versao}/${controller}`, dto);
    },
    ObterUltimoOnboardingConcluidoPorDocFederal: function (docFederal) {
        return util.Axios.Get(`${versao}/${controller}` + '/ultimo-onboarding-concluido/' + docFederal);
    },
    RegistrarVisto: function (dto) {
        return util.Axios.Post(`${versao}/${controller}` + '/registrar-visto', dto);
    },
    ObterVisto: function (params) {
        const compUrl = new URLSearchParams(params).toString();
        return util.Axios.Get(`${versao}/${controller}` + '/visto?' + compUrl);
    },
    Finalizar: function (dto) {
        return util.Axios.Post(`${versao}/${controller}` + '/finalizar', dto);
    }
})
