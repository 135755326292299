import Vue from 'vue';
import App from './App.vue';
import core from '@bmp-vue/core';
import arrays from './api/arrays';
import enums from './api/enum';
import ui from './api/ui';
import utils from './api/util';
import functions from '@/functions/index.js'
  
Vue.config.productionTip = false;

const controllers = require.context('@/api/controllers', true, /\.js$/);

const paths = require('@/plugins/router/paths').default;
const theme = require('@/plugins/vuetify/theme').default;

Vue.use(core, {
  controllers,
  paths,
  theme,
  defaultViews: false,
  carregaEnums: false,
  extendRouterBeforeEach: function (vm, to, from, next) {
    return true
  },
  mount: (options) => {
    require('@/components/')
    Vue.prototype.$api.UI = {
      ...Vue.prototype.$api.UI,
      ...ui
    }
    Vue.prototype.$api.Arrays = {
      ...Vue.prototype.$api.Arrays,
      ...arrays
    }
    Vue.prototype.$api.Enums = {
      ...Vue.prototype.$api.Enums,
      ...enums
    }
    Vue.prototype.$api.UTIL = {
      ...Vue.prototype.$api.UTIL,
      ...utils
    }
    Vue.prototype.$api.Functions = {
      ...Vue.prototype.$api.Functions,
      ...functions
    }
    Vue.prototype.$api.Autenticacao = Vue.prototype.$api.autenticacao
    new Vue({
      ...options,
      render: h => h(App)
    }).$mount('#app');
  }
});