
export default {
  AjustarSituacaoOnboardingConta(response) {
    const situacaoAguardandoAnalisePendenciado = 8
    const situacaoAguardandoAnaliseOnboarding = 6

    const situacaoAguardandoAnaliseRecusada = 7
    const situacaoAguardandoAnaliseCancelada = 5

    for (let count = 0; response.length > count; count++) {
      if (response[count].situacaoSolicitacaoConta == situacaoAguardandoAnalisePendenciado) {
        response[count].situacao = situacaoAguardandoAnaliseOnboarding
        continue
      }

      if (response[count].situacaoSolicitacaoConta ==  situacaoAguardandoAnaliseRecusada) {
        response[count].situacao = situacaoAguardandoAnaliseCancelada
        continue
      }

      if (response[count].situacaoSolicitacaoConta ==  situacaoAguardandoAnaliseCancelada) {
        response[count].situacao = situacaoAguardandoAnaliseRecusada
        continue
      }
    }

    return response
  }
}