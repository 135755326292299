export default
{
    TiposPessoa: [
        { value: 1, text: 'PF' }, 
        { value: 2, text: 'PJ' }
    ],
    TipoInformacao: [
        { value: 1, text: 'Correntista' },
        { value: 2, text: 'Socio' },
        { value: 3, text: 'Bureau' },
        { value: 4, text: 'ControladorDaConta' },
        { value: 5, text: 'Biometria' }
    ],
    TipoEmpresa: [
        { value: 1, text: 'FIDC' },
        { value: 2, text: 'Factory' },
        { value: 3, text: 'Securitizadora' },
        { value: 4, text: 'Outros' }
    ],
    TipoPreenchimento: [
        { value: 1, text: 'Escrow' },
        { value: 2, text: 'Titular' },
        { value: 3, text: 'Parceiro' },
        { value: 4, text: 'Empresa' }
      ],
    TipoArquivoSocio: [
        { value: 1, text: 'Procuração' },
        { value: 2, text: 'Identificação' }
    ],
    FinalizacaoSolicitacao: [
        { value: 1, text: 'Aprovar' },
        { value: 2, text: 'Pendenciar' },
        { value: 3, text: 'Recusar' }
    ],
    ModeloCobrancaTaxa: [
        { value: 0, text: 'Desativado' },
        { value: 1, text: 'Parceiro' },
        { value: 2, text: 'Cliente' }
    ],
    EstadoCivil: [
        { value: 1, text: 'Solteiro(a)' },
        { value: 2, text: 'Casado(a)' },
        { value: 3, text: 'Desquitado(a)' },
        { value: 4, text: 'Divorciado(a)' },
        { value: 5, text: 'Separado(a)' },
        { value: 6, text: 'Viúvo(a)' },
        { value: 7, text: 'Outros' },
        { value: 8, text: 'Não Informado' },
        { value: 9, text: 'Vinculo Conjugal' }
    ],
    ContextoOnboarding: [
        { value: 0, text: 'Nenhum' }, 
        { value: 1, text: 'Correntista' },
        { value: 2, text: 'Operador Backoffice' }, 
        { value: 3, text: 'Operador Retaguarda' },
        { value: 4, text: 'Operador Frontend' },
        { value: 5, text: 'KYC Operador Retaguarda' },
        { value: 6, text: 'KYC Operador FrontEnd' },
        { value: 7, text: 'Correntista PJ' },
        { value: 8, text: 'Sócio Correntista PJ' }
    ],
    Situacao: [
        { value: 1, text: 'Ativado' }, 
        { value: 2, text: 'Desativado' }
    ],
    SituacaoAnaliseCadastral: [
        { value: 1, text: 'Pendente' },
        { value: 2, text: 'Aprovada' },
        { value: 3, text: 'Reprovada' },
        { value: 4, text: 'Pendente Consulta' },
        { value: 5, text: 'Cancelada' }
    ],
    TipoBureau: [
        { value: 1, text: 'Cadastro restritivo' },
        { value: 2, text: 'Indício de fraude' },
        { value: 3, text: 'Óbito e fiscal' },
        { value: 4, text: 'Mídia desabonadora' },
        { value: 5, text: 'E-mail age' },
        { value: 6, text: 'Análise restritiva' },
        { value: 7, text: 'Criminal detalhe' },
        { value: 8, text: 'Segban' },
        { value: 12, text: 'Mídia desabonadora PJ' },
        { value: 14, text: 'Análise cadastral' },
        { value: 15, text: 'Análise cadastral PJ' }
    ],
    SituacaoConfiguracaoBureau: [
        { value: 0, text: 'Todos' },
        { value: 1, text: 'Ativado' }, 
        { value: 2, text: 'Desativado' }
    ],
    SolicitacoesAnalise: [
        { value: 1, text: 'Todas' },
        { value: 2, text: 'Em consulta' },
        { value: 3, text: 'Análises pendentes' },
        { value: 4, text: 'Aprovações pendentes' },
    ],
    ModeloConta: [
        { value: 1, text: 'Movimento' },
        { value: 2, text: 'Escrow' },
        { value: 3, text: 'Vinculada' }
    ],
    TipoPermissao: [
        { value: 1, text: 'Criação' },
        { value: 2, text: 'Edição' },
        { value: 4, text: 'Criação Vínculo' },
        { value: 5, text: 'Edição Vínculo' }
    ],
    SituacaoExecucao: [
        { value: 1, text: 'Pendente' },
        { value: 2, text: 'Executando' },
        { value: 3, text: 'Executado' },
        { value: 4, text: 'Ignorado' },
        { value: 5, text: 'Validado' },
        { value: 99, text: 'Erro Sistema'}
    ],
    SituacaoAprovacaoAnalise:[
        { value: 1, text: 'Aprovado' },
        { value: 2, text: 'Reprovado' },
    ],
    SituacaoOnboaarding:[
        { value: 1, text: 'Pendente' },
        { value: 2, text: 'Aprovado' },
        { value: 3, text: 'Reprovado' },
    ],
    PerfilOperadorOnboardingExibicao:[
        { value: 2, text: 'Analista' },
        { value: 3, text: 'Analista' },
        { value: 4, text: 'Aprovador' },
        { value: 5, text: 'Aprovador' }
    ],
    SituacaoValidacaoOnboardingDetalhe:[
        { value: 1, text: 'Pendente Validacao' },
        { value: 2, text: 'Aprovado' },
        { value: 3, text: 'Reprovado' },
        { value: 98, text: 'Validacao Inexistente' },
        { value: 99, text: 'Erro Sistema' }
    ],
    DescricaoValidacaoOnboardingDetalhe:[
        { value: 1, text: 'Análise pendente de validação' },
        { value: 2, text: 'Aprovado' },
        { value: 3, text: 'Análise contém restrição' },
        { value: 98, text: 'Validação não encontrada, realize a consulta do bureau novamente' },
        { value: 99, text: 'Ocorreu um erro inesperado na análise' }
    ],
    ApiOrigem: [
        { value: 0, text: 'BackOffice' },
        { value: 1, text: 'API FrontEnd' },
        { value: 2, text: 'API Externa' },
        { value: 3, text: 'Retaguarda' },
        { value: 99, text: 'Outros' }
    ],
    StatusSolicitacoes: [
        { value: 2, text: 'Em consulta' },
        { value: 3, text: 'Análise pendente' },
        { value: 4, text: 'Aprovação pendente' },
    ],
    ResponsabilidadeSocio: [
      { value: 1, text: 'Sócio' },
      { value: 2, text: 'Diretor' },
      { value: 3, text: 'Procurador' },
      { value: 4, text: 'Gerente' },
      { value: 5, text: 'Coordenador' }
    ],
    TipoAssinaturaSocio: [
      { value: 1, text: 'Assina isoladamente' },
      { value: 2, text: 'Assina em conjunto' },
      { value: 3, text: 'Não Assina' },
    ],
    SituacaoAnaliseCadastralContas: [
        { value: 1, text: 'Aguardando análise' },
        { value: 2, text: 'Aprovada' },
        { value: 3, text: 'Reprovada' },
        { value: 4, text: 'Em consulta' },
        { value: 5, text: 'Cancelada' },
        { value: 6, text: 'Aguardando análise' },
        { value: 7, text: 'Recusada' }
    ],
    FiltroSituacaoAnaliseCadastralContas: [
        { value: 2, text: 'Aprovada' },
        { value: 5, text: 'Recusada' }
    ]
}
