import enums from '../../api/enum/index'

let tipoPerfilOperador = enums.TipoPerfilOperadorOnboarding

export default [
  {
    path: '/',
    view: 'Login',
    title: 'Login',
    menu: false,
    dir: 'views/',
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/recuperar',
    view: 'RecuperacaoSenha',
    title: 'Recuperação de Senha',
    dir: 'views/',
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/alertas-login',
    view: 'AlertasLogin',
    title: 'Alertas de Login',
    dir: 'views/',
    menu: false,
    params: true,
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/solicitacao-recuperacao-senha',
    view: 'SolicitacaoRecuperacaoSenha',
    title: 'Solicitação de Recuperação de Senha',
    dir: 'views/',
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    view: 'Dashboard',
    title: 'Dashboard',
    dir: 'views/app/',
    menu: true,
    meta: {
      // role: ['Nenhum']
    }
  },
  {
    path: '/admin',
    icon: 'fas fa-edit',
    title: 'Admin',
    dir: 'views/app/admin/',
    menu: true,
    meta: {
      roles: [tipoPerfilOperador.Administrador],
      allRolesRequired: true
    },
    items: [
      {
        title: 'Parametrização',
        path: '/parametrizacao',
        dir: 'parametrizacao/',
        menu: true,
        meta: {
          roles: [tipoPerfilOperador.Administrador],
          allRolesRequired: true
        },
        items: [
          {
            view: 'Analise',
            title: 'Análise',
            path: '/analise',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          },
          {
            view: 'Bureau',
            title: 'Bureau',
            path: '/bureau',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/analises',
    icon: 'fas fa-edit',
    title: 'Análises',
    dir: 'views/app/analises/',
    menu: true,
    meta: {
      roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
      allRolesRequired: false
    },
    items: [
      {
        title: 'Operadores',
        path: '/operadores',
        dir: 'operadores/',
        menu: true,
        meta: {
          roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
          allRolesRequired: false
        },
        items: [
          {
            title: 'Bureau',
            path: '/bureau',
            dir: 'operador/',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
              allRolesRequired: false
            },
            items: [
              {
                view: 'Retaguarda',
                title: 'Retaguarda',
                path: '/retaguarda',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              },
              {
                view: 'FrontEnd',
                title: 'Front-End',
                path: '/front-end',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              }
            ]
          },
          {
            title: 'Biometria',
            path: '/biometria',
            dir: 'kyc/',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
              allRolesRequired: false
            },
            items: [
              {
                view: 'Retaguarda',
                title: 'Retaguarda',
                path: '/retaguarda',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              },
              {
                view: 'FrontEnd',
                title: 'Front-End',
                path: '/front-end',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              }
            ]
          }
        ]
      },
      {
        title: 'Contas',
        path: '/contas',
        dir: 'contas/',
        menu: true,
        meta: {
          roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
          allRolesRequired: false
        },
        items: [
          {
            path: '/pessoa-fisica',
            title: 'Pessoa Física',
            dir: 'pessoaFisica/',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
              allRolesRequired: false
            },
            items: [
              {
                view: 'EscrowPessoaFisica',
                title: 'Escrow',
                path: '/escrow',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              },
              {
                view: 'MovimentoPessoaFisica',
                title: 'Movimento',
                path: '/movimento',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              }
            ]
          },
          {
            path: '/pessoa-juridica',
            title: 'Pessoa Jurídica',
            dir: 'pessoaJuridica/',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
              allRolesRequired: false
            },
            items: [
              {
                view: 'EscrowPessoaJuridica',
                title: 'Escrow',
                path: '/escrow',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              },
              {
                view: 'MovimentoPessoaJuridica',
                title: 'Movimento',
                path: '/movimento',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              }
            ]
          }
        ]
      },  
    ]
  },
  {
    path: '/historico',
    icon: 'fas fa-edit',
    title: 'Histórico de Análises',
    dir: 'views/app/historico/',
    menu: true,
    meta: {
      // role: ['Nenhum']
    },
    items: [
      {
        title: 'Operadores',
        path: '/operadores',
        dir: 'operadores/',
        menu: true,
        meta: {
          roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
          allRolesRequired: false
        },
        items: [
          {
            title: 'Bureau',
            path: '/bureau',
            dir: 'operador/',
            menu: true,
            meta: {
              // role: ['Nenhum']
            },
            items: [
              {
                view: 'Retaguarda',
                title: 'Retaguarda',
                path: '/retaguarda',
                menu: true,
                meta: {
                  // role: ['Nenhum']
                }
              },
              {
                view: 'FrontEnd',
                title: 'Front-End',
                path: '/frontend',
                menu: true,
                meta: {
                  // role: ['Nenhum']
                }
              }
            ]
          },
          {
            title: 'Biometria', 
            path: '/biometria',
            dir: 'kyc/',
            menu: true,
            meta: {
              // role: ['Nenhum']
            },
            items: [
              {
                view: 'Retaguarda',
                title: 'Retaguarda',
                path: '/retaguarda',
                menu: true,
                meta: {
                  // role: ['Nenhum']
                }
              },
              {
                view: 'FrontEnd',
                title: 'Front-End',
                path: '/front-end',
                menu: true,
                meta: {
                  // role: ['Nenhum']
                }
              }
            ]
          },
        ]
      },
      {
        title: 'Contas',
        path: '/contas',
        dir: 'contas/',
        menu: true,
        meta: {
          roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
          allRolesRequired: false
        },
        items: [
          {
            path: '/pessoa-fisica-historico',
            title: 'Pessoa Física',
            dir: 'pessoaFisica/',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
              allRolesRequired: false
            },
            items: [
              {
                view: 'EscrowPessoaFisica',
                title: 'Escrow',
                path: '/escrow',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              },
              {
                view: 'MovimentoPessoaFisica',
                title: 'Movimento',
                path: '/movimento',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              }
            ]
          },
          {
            path: '/pessoa-juridica-historico',
            title: 'Pessoa Jurídica',
            dir: 'pessoaJuridica/',
            menu: true,
            meta: {
              roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
              allRolesRequired: false
            },
            items: [
              {
                view: 'EscrowPessoaJuridica',
                title: 'Escrow',
                path: '/escrow',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              },
              {
                view: 'MovimentoPessoaJuridica',
                title: 'Movimento',
                path: '/movimento',
                menu: true,
                meta: {
                  roles: [tipoPerfilOperador.Analista, tipoPerfilOperador.Aprovador],
                  allRolesRequired: false
                }
              }
            ]
          }
        ]
      }, 
    ]
  }
]
