import util from '@bmp-vue/core/api/util'

const controller = 'controlador-conta'
const versao = 'v1'

export default Object.assign({}, util.GetCrud(controller, null), {
    ObterControlador: function (id) {
        return util.Axios.Get(`${versao}/${controller}` + '/controlador', id);
    },
    ObterControladorSolicitacao: function (codigoSolicitacao) {
        return util.Axios.Get(`${versao}/${controller}` + '/controlador-solicitacao', codigoSolicitacao);
    }
})
